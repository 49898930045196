import React, { useState } from 'react';
import { css } from "@emotion/core";
import styled from '@emotion/styled';
import addToMailchimp from 'gatsby-plugin-mailchimp';


const alignCenter = css`
text-align:center;
margin-bottom:2.5rem;
`;

const buttonStyle = css`
color:#fff;
background-color:rgb(229, 57, 130);
border:1px solid rgb(229, 57, 130);
margin-left:1rem;
border-radius:5px;
padding:7px 12px;
letter-spacing:1px;
cursor:pointer;
@media (max-width: 500px) {
  margin-top:.8rem;
}
`;
const inputStyle = css`
padding:5px 15px;
`;

const SubmitButton = styled.button`
${buttonStyle}
cursor:${props => props.disabled && 'context-menu'};
`;

const NewsLetter = ({ res }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const changeHandler = (e) => {
    setEmail(e.target.value);
  }
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
        const responce = await addToMailchimp(email);
        // console.log(responce);
        if (responce.result === 'error') {
          res(responce);
          setIsLoading(false);
        } else {
          res(responce);

          setEmail('');
          setIsLoading(false);

        }
      }

    } catch (err) {
      // console.log(err);
      setIsLoading(false);

    }
  }

  return (
    <div css={alignCenter}>
      <h4>Please subscribe our news letter</h4>
      <p>If you like what you just read. Please subscribe to our newsletter. So new content will be delivered to your inbox :)</p>
      <form onSubmit={submitHandler}>
        <input type="email" placeholder="Enter you email" name="EMAIL" css={inputStyle} value={email} onChange={changeHandler} disabled={isLoading} required />
        <SubmitButton type="submit" disabled={isLoading}>{isLoading ? '...wait' : 'Subscribe'}</SubmitButton>
      </form>

    </div>


  );

}
export default NewsLetter;