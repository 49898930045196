import React from 'react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

// adding animation
const slideDown = keyframes`

      from {
        transform:translateY(-60px);
      }
      to{
        transform:translateY(0px);
      }

`;
const hideAlert = keyframes`
    from {
      transform:translateY(0px);
    }
    to{
      transform:translateY(-60px);
    }

`;
const fadeShow = css`animation:${slideDown} .3s ease forwards`;
const fadeHide = css`animation:${hideAlert} .4s ease forwards`;


const ActiveAlert = styled.div`
position:fixed;
top:0;
z-index:100;
width:100%;
padding:1rem;
color:#fff;
transform:translateY(-60px);
background:${props => (props.res === 'error' ? '#f72525d1' : props.res === 'success' && '#4cb53fd1')};
`;

const Alert = ({ result, hide }) => {
  return (
    <ActiveAlert
      css={css`
  ${result && fadeShow}
  ${hide && fadeHide}
  `}
      res={result && result.result}
    >{result && result.msg.split('<')[0]}</ActiveAlert>
  );

}

export default Alert;