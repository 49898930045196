/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 70, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      love: file(absolutePath: { regex: "/icons8-heart-outline-48.png/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitter: file(absolutePath: { regex: "/twitter.png/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      instagram: file(absolutePath: { regex: "/instagram.png/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkedIn: file(absolutePath: { regex: "/icons8-linkedin-48.png/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      github: file(absolutePath: { regex: "/icons8-github-48.png/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            linkedin
            github
            instagram
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      className="bio__container"
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image
        className="bio__container--image"
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          minWidth: 70,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div style={{ marginBottom: 0 }}>
        Hii this is <strong>{author.name}</strong>. I live in {` `}India. I{" "}
        <Image
          fixed={data.love.childImageSharp.fixed}
          alt={author.name}
          style={{ height: 0, width: 20, paddingTop: 15 }}
        />{" "}
        {author.summary}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            href={`https://twitter.com/${social.twitter}`}
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: "center",
              color: "transparent",
            }}
          >
            <Image
              fixed={data.twitter.childImageSharp.fixed}
              alt={author.name}
            />
          </a>
          <a
            href={`https://www.instagram.com/${social.instagram}`}
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: "center",
              color: "transparent",
            }}
          >
            <Image
              fixed={data.instagram.childImageSharp.fixed}
              alt={author.name}
            />
          </a>
          <a
            href={`https://www.linkedin.com/in/${social.linkedin}/`}
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: "center",
              color: "transparent",
            }}
          >
            <Image
              fixed={data.linkedIn.childImageSharp.fixed}
              alt={author.name}
            />
          </a>
          <a
            href={`https://github.com/${social.github}`}
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: "center",
              color: "transparent",
            }}
          >
            <Image
              fixed={data.github.childImageSharp.fixed}
              alt={author.name}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Bio
